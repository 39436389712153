import * as React from 'react'
import {Menu} from "./Menu";
import {Profile} from "../../modules/profile/ProfileTypes";
import AppBarComponent from "./AppBarComponent";
import LanguageDrawer from "./LanguageDrawer";
import CustomDialog from "./CustomDialog";
import {ApplicationState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import { getLanguages, saveProfile, showSnackbar } from "../../modules/profile/ProfileActions";
import {closeCustomDialog} from "../../modules/order/OrderActions";

const HeaderComponent: React.FC = () => {
    const [openMenu, setOpenMenu] = React.useState<boolean>(false)
    const [openLanguages, setOpenLanguages] = React.useState<boolean>(false)
    let location = useLocation();
    const dispatch = useDispatch()

    const translationActiveState = useSelector((state: ApplicationState) => state.config.translationActive)
    const isTranslationActive = translationActiveState && (location.pathname === "/menu" || location.pathname === "/account/login")
    const isProfileEnabled = useSelector((state: ApplicationState) => state.profile.activeProfileID != null && state.config.loadDataProfiles)
    const profiles = useSelector((state: ApplicationState) => state.profile.profiles)
    const cmealLanguages = useSelector((state: ApplicationState) => state.profile.languages)
    const activeProfile = useSelector((state: ApplicationState) => state.profile.patient)
    const configLanguages = useSelector((state: ApplicationState) => state.config.languages)
    const isAuthenticated = useSelector((state: ApplicationState) => state.auth.isAuthenticated)
    const showMenuIcon = isAuthenticated
    const showReturnIcon = isAuthenticated && (location.pathname === "/order" || location.pathname === "/profile") && (activeProfile.canOrderMeals === undefined ? true : activeProfile.canOrderMeals)
    const  customDialog = useSelector((state: ApplicationState) => state.order.customDialog)


    const getAllLanguages =  () => {
        getLanguages()(dispatch)
    }

    const saveLanguageProfile = (patientNumber: string, isDisclaimerChecked: boolean, preferredNutritionNumber: number, preferredDietNumber: number, preferredLanguage: number, isUsingApp: boolean, isAskingConsult: boolean, newCulture: string, profile: Profile) => {
        saveProfile(patientNumber, isDisclaimerChecked, preferredNutritionNumber, preferredDietNumber, preferredLanguage, isUsingApp, isAskingConsult, newCulture, profile)(dispatch)
    }

    const showSnackbarError = (text: string) => {
        dispatch(showSnackbar(text, "error", 10000));        
    }

    const closeDialog = () => {
        dispatch(closeCustomDialog())
    }

        return (
        <>
            <Menu cmealLanguages={cmealLanguages} getLanguages={getAllLanguages} isProfileEnabled={isProfileEnabled}
                  openMenu={openMenu} setOpenMenu={setOpenMenu} profiles={profiles}/>
                <LanguageDrawer saveProfile={saveLanguageProfile}
                    configLanguages={configLanguages}
                    profiles={profiles}
                    cmealLanguages={cmealLanguages}
                    activeProfile={activeProfile}
                    openLanguages={openLanguages}
                    setOpenLanguages={setOpenLanguages}
                    showSnackbarError={showSnackbarError}
                    />
                <AppBarComponent isTranslationActive={isTranslationActive} showMenuIcon={showMenuIcon}
                             showReturnIcon={showReturnIcon} setOpenLanguages={setOpenLanguages}
                             setOpenMenu={setOpenMenu}/>
            <CustomDialog customDialog={customDialog}
                          closeCustomDialog={closeDialog}/>
        </>
    )
}

export default HeaderComponent